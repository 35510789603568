$TextPurple: #7157A4;
$TextPrupleDark: #4A346F;
$TextGreen: #17E5AA;
$TextGray: #9497A3;
$TextWhite: #FFFFFF;
$TextBlack: #000000;
$BgNavBtn: rgba(179, 179, 179, 0.2);
$BgShadowRect: #0F1670;
$BgDiskGreen: #2CDD8C;
$BgAdvantages: #1C1629;
$BgDisk: #392C52;
$BgNav: #2B213E;
$BorderInput: #D5D7DE;
$BgOtherOption: #459B9C