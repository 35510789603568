#fp-nav {
    height: calc(100vh - 30px)  !important;
    right: 30px !important;
    top: 30px !important;
    margin-top: 0 !important;

    ul {

        li {
            width: 20px  !important;
            height: 20px  !important;
            margin: 5px 0  !important;

            a {
                width: 100%  !important;
                height: 100%  !important;

                span {
                    width: 13px  !important;
                    height: 13px  !important;
                    margin: auto  !important;
                    top: 0  !important;
                    bottom: 0  !important;
                    left: 0  !important;
                    right: 0  !important;
                    background-color: $BgNavBtn  !important;
                    transition: none  !important;
                }

                &.active {

                    span {
                        width: 100%  !important;
                        height: 100%  !important;
                        margin: 0  !important;
                        border: 3px solid $TextGreen  !important;
                        background-color: transparent  !important;
                        padding: 0  !important;
                    }
                }
            }

            .fp-tooltip {
                text-transform: uppercase  !important;
                font-family: stemRegular  !important;
                color: $TextGreen !important;
                font-size: 13px !important;
                line-height: 24px !important;
                top: 0;

                &.fp-right {
                    right: 30px  !important;
                }
            }

            &:hover {

                a {
                    width: 13px  !important;
                    height: 13px  !important;
                    margin: auto  !important;

                    span {
                        width: 100%  !important;
                        height: 100%  !important;
                        margin: 4px 0  !important;
                        background-color: $TextPurple  !important;
                        padding: 0  !important;
                        border: 0  !important;
                    }
                }

                a.active {
                    transition: all .1s ease-in-out  !important;
                }

                .fp-tooltip {
                    color: $TextPurple !important;
                    font-size: 13px  !important;
                    line-height: 24px  !important;
                }
            }
        }
    }

    &:after {
        content: ""  !important;
        width: 1px;
        height: calc(100vh - 200px)  !important;
        background-color: $BgNavBtn  !important;
        position: fixed  !important;
        margin: auto  !important;
        bottom: 0  !important;
        left: 0  !important;
        right: 0  !important;
    }
}