@import "./bootstrap.css";
@import "./fontawesome-all.css";
@import "./textfonts.scss";
@import "./colorset.scss";
@import "./navigation.scss";
@import "./animation.scss";

body {
    font-size: 10px;
    font-family: 'sans-serif';
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    margin: 0;
}

img {
    width: 100%;
}

ul {
    padding: 0;
}

// .fp-section.fp-table, .fp-slide.fp-table {
//     position: fixed;
//     left: 0;
//     background-color: #FFFFFF;
// }

// .fp-section.fp-table {
//     &:nth-child(1) {
//         z-index: 7;
//     }
//     &:nth-child(2) {
//         z-index: 6;
//     }
//     &:nth-child(3) {
//         z-index: 5;
//     }
//     &:nth-child(4) {
//         z-index: 4;
//     }
//     &:nth-child(5) {
//         z-index: 3;
//     }
//     &:nth-child(6) {
//         z-index: 2;
//     }
//     &:nth-child(7) {
//         z-index: 1;
//     }
// }

#home-nav {
    position: absolute;
    bottom: 2%;
    right: 3%;
    z-index: 999;
    
    a {
        font-size: 1.6rem;
        color: $TextGreen;
        padding: 5px;
        margin: 3px;
        position: relative;

        &:last-child {
            &:after {
                content: "\f1e0";
                font-size: 1.6rem;
                color: $TextGreen;
                font-family: 'Font Awesome 5 Pro';
                font-weight: 900;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                font-style: normal;
                font-feature-settings: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
            }

            & > * {
                display: none;
            }
        }
    }

    button {
        color: $TextGreen;
        margin: 3px;
        border: none;
        background-color: unset;
        font-size: 1.6rem;

        &:focus {
            outline: none;
        }
    }
}

.section__page {
    width: 100%;
    height: 100vh;
    display: flex;
    min-height: 600px;
}

.home {
    background-image: url('../backgrounds/HomeBg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    &__side {
        display: flex;
        width: 100%;
    }

    &__left{
        flex-direction: column;
        align-items: center;
        justify-content: center
    }

    &__content {
        width: min-content;
        margin-left: 12%;
        margin-top: -20%;

        h1 {
            font-size: 5.73vw;
            color: $TextPurple;
            line-height: 6.88vw;
            width: min-content;
            margin-top: 7%;
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        height: 26%;
        color: $TextPurple;
        background-repeat: no-repeat;
        background-position: left;
        background-image: url('../backgrounds/LaginaIcon.svg');
        background-size: contain;
        font-size: 2vw;
        font-family: StemBold;
        padding-left: 30%;
    }

    &__right {
        align-items: center;
    }

    &__image {
        width: 90%;
        height: 42vw;
        background-image: url('../backgrounds/home-image.png');
        background-repeat: no-repeat;
        background-size: contain;
    }

    button{
        position: absolute;
        top: inherit;
        left: inherit;
        padding: 18px 63px;
        border:2px solid $TextGreen;
        border-radius: 35px;
        background-color: rgba(255, 255, 255, 0.9);
        color:$TextPurple;
        font-family: 'StemBold';
        font-size: 2.2rem;
        letter-spacing: .25px;
        margin-top: 20px;
    }
}

.about-home {

    &__side {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__left {
        width: 37%;
        background-color: $TextGreen;

        h2 {
            font-size: 2.3vw !important;
            line-height: 3vw !important;
            position: absolute;
            color: #FFFFFF;
            top: 15%;
            left: 9%;
            z-index: 3;
            max-width: 21vw;
        }
    }

    &__right {
        display: flex;
        width: 63%;
        background: rgba(95,47,170,1);
        background: -moz-linear-gradient(45deg, rgba(95,47,170,1) 0%, rgba(34,188,152,1) 100%);
        background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(95,47,170,1)), color-stop(100%, rgba(34,188,152,1)));
        background: -webkit-linear-gradient(45deg, rgba(95,47,170,1) 0%, rgba(34,188,152,1) 100%);
        background: -o-linear-gradient(45deg, rgba(95,47,170,1) 0%, rgba(34,188,152,1) 100%);
        background: -ms-linear-gradient(45deg, rgba(95,47,170,1) 0%, rgba(34,188,152,1) 100%);
        background: linear-gradient(45deg, rgba(95,47,170,1) 0%, rgba(34,188,152,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5f2faa', endColorstr='#22bc98', GradientType=1 );
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1 {
            position: relative;
            font-size: 5.42vw;
            line-height: 6.5vw;
            color: $TextWhite;
            z-index: 5;
            text-align: right;
            max-width: 28vw;
        }
    }

    &__right-content {
        position: relative;
        left: 10%;
        top: 5%;
    }

    &__nav {
        float: right;
        margin-top: 12%;
    }
    
    &__icon {
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;
        margin: auto;
        z-index: 2;
    }

    &__big-rectangle {
        position: absolute;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 11%;
        width: 36%;
        height: 35vw;
        background-image: url('../backgrounds/AboutBigRect.svg');
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
    }

    &__shadow-rectangle {
        top: 0;
        bottom: 0;
        left: 0;
        right: 7%;
        width: 14%;
        height: 14vw;
        background-color: #0F1670;
        opacity: 0.7;
    }

    &__burger-icon {
        width: 5.72%;
        height: 4.06vw;
        top: 24.5%;
        left: 0;
        right: 9%;
        background-image: url('../backgrounds/burger-icon.svg');
    }

    &__rectangle-icon {
        width: 4.5%;
        height: 4.5vw;
        top: 10%;
        right: 25%;
        background-image: url('../backgrounds/rectangle-icon.svg');
    }

    &__circle-icon {
        width: 5.6%;
        height: 5.6vw;
        top: 10%;
        bottom: 0;
        left: 7%;
        background-image: url('../backgrounds/circle-icon.svg');
    }

    &__triangle-icon {
        width: 8%;
        height: 8vw;
        background-image: url('../backgrounds/triangle-icon.svg');
        bottom: 5%;
        left: 7%;
        right: 0;
    }

    &__disk {
        width: 7.66%;
        height: 7.66vw;
        border-radius: 50%;
        top: 16%;
        right: 7%;
        background-color: $BgDiskGreen;
        opacity: 0.7;
    }

    &__sign {
        width: 19.8%;
        height: 12vw;
        top: 18%;
        bottom: 0;
        left: 0;
        right: 18%;
        background-image: url('../backgrounds/about-sign.svg');
    }
}

.about-sign {
    width: 100%;
    height: 100vh;
    min-height: 500px;
    display: flex;
    position: relative;

    &__sign-page {
        display: flex;
        width: 100%;
        height: 100%;
        background: rgba(95,47,170,1);
        background: -moz-linear-gradient(45deg, rgba(95,47,170,1) 0%, rgba(34,188,152,1) 100%);
        background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(95,47,170,1)), color-stop(100%, rgb(62, 70, 68)));
        background: -webkit-linear-gradient(45deg, rgba(95,47,170,1) 0%, rgba(34,188,152,1) 100%);
        background: -o-linear-gradient(45deg, rgba(95,47,170,1) 0%, rgba(34,188,152,1) 100%);
        background: -ms-linear-gradient(45deg, rgba(95,47,170,1) 0%, rgba(34,188,152,1) 100%);
        background: linear-gradient(45deg, rgba(95,47,170,1) 0%, rgba(34,188,152,1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5f2faa', endColorstr='#22bc98', GradientType=1 );
    }

    &__side {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__inner-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    &__choices {
        max-width: 636px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__nav, &__nav--other  {
        display: block;
        width: 300px;
        height: 70px;
        background-color: $TextGreen;
        text-align: center;
        line-height: 70px;
        border-radius: 50px;
        color: $TextWhite;
        font-size: 2.4rem;
        
        &:hover {
            text-decoration: none;
            color: $TextWhite;
        }

        &--other {
            margin-right: 4%;
            background-color: $BgOtherOption;
        }

        &--disabled {
            opacity: 0.7;
            -webkit-user-select: none; /* Safari */        
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */
        }
    }

    &__turn-back {
        font-size: 1.8rem;
        padding: 5px;
        color: $TextGreen;
        top: 4.2%;
        left: 4.2%;
        position: absolute;

        &:hover {
            text-decoration: none;
            color: $TextGreen;
        }

        i {
            position: relative;
            color: $TextWhite;
            left: -20px;
            top: 3px;
            font-size: 2.4rem;
        }
    }

    h2 {
        font-size: 6rem;
        line-height: 72px;
        color: $TextWhite;
    }

    h3 {
        color: $TextWhite;
    }

    p {
        font-size: 2.4rem;
        color: $TextWhite;
    } 
}

.about-sp1 {
    
    * {
        cursor: url('../backgrounds/cursor-hand.svg'), auto;

        &:active {
            cursor: url('../backgrounds/cursor-hand-clicked.svg'), auto;
        }
    }

    a {
        cursor: pointer;

        &:active {
            cursor: pointer;
        }
    }
    
    &__left {
        width: 48%;
        flex-direction: column;
        padding: 20px;
    }

    &__inner-content {
        width: 100%;
        max-width: 495px;
        
        p {
            padding-right: 10%;
            margin-top: 4%;
        }
    }

    &__right {
        width: 52%;
        position: relative;
        flex-wrap: wrap;
    }

    &__document {
        width: 12.42vw;
        position: relative;
        margin: 1vw;
        z-index: 2;

        &:after {
            content:"";
            position: absolute;
            z-index: -1;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &:nth-child(1) {
            top: 5vh;
            left: -2%;

            &:after {
                opacity: 0.57;
                left: -35%;
                top: 7%;
                width: 18.4vw;
                height: 18.4vw;
                background-image: url('../backgrounds/sozlesmeBg.svg');
            }
        }

        &:nth-child(2) {
            top: 16vh;

            &:after {
                opacity: 0.57;
                left: 2%;
                top: -20%;
                width: 13.5vw;
                height: 13.5vw;
                background-image: url('../backgrounds/teslimatBg.svg');
            }
        }

        &:nth-child(3) {
            top: 7vh;

            &:after {
                left: 25%;
                top: 55%;
                width: 11.4vw;
                height: 11.4vw;
                background-image: url('../backgrounds/kontratBg.svg');
            }
        }

        &:nth-child(4) {
            left: -12%;
            bottom: 5vh;

            &:after {
                opacity: 0.57;
                left: 30%;
                top: 20%;
                width: 14.7vw;
                height: 14.7vw;
                background-image: url('../backgrounds/makbuzBg.svg');
            }
        }

        &:nth-child(5) {

            &:after {
                opacity: 0.57;
                left: 50%;
                top: -8%;
                width: 10.4vw;
                height: 10.4vw;
                background-image: url('../backgrounds/izinBg.svg');
            }
        }
    }
}

.about-sp2 {
    &__left {
        width: 52%;
        flex-direction: column;
        padding: 20px;
    }

    &__inner-content {
        width: 100%;
        max-width: 640px;
        margin-top: 8%;
        margin-left: 5%;
        
        p {
            padding-right: 15%;
            margin-top: 4%;
        }
    }

    &__choices {
        margin-top: 12%;
    }

    &__right {
        width: 48%;
        position: relative;

        span {
            position: absolute;
            width: 2.6%;
            height: 36.5vw;
            transform: skewX(-15deg);
            background-color: $TextGreen;
            bottom: 0;
            right: 0;
            margin: auto;

            &:nth-of-type(1) {
                top: 7%;
                left: -40.5%;
            }

            &:nth-of-type(2) {
                top: -5%;
                left: -27%;
            }

            &:nth-of-type(3) {
                top: 4%;
                left: -20%;
            }

            &:nth-of-type(4) {
                top: -2%;
                left: -8.5%;
            }

            &:nth-of-type(5) {
                top: 0.5%;
                left: 0.5%;
            }

            &:nth-of-type(6) {
                top: 4%;
                left: 9%;
            }

            &:nth-of-type(7) {
                top: -2%;
                left: 20.5%;
            }

            &:nth-of-type(8) {
                top: 7%;
                left: 27.5%;
            }

            &:nth-of-type(9) {
                top: -5%;
                left: 41%;
            }
        }
    }

    &__document {
        position: relative;
        width: 43%;
        height: 27.5vw;
        padding: 2vw;
        z-index: 2;
        margin-right: 10%;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('../backgrounds/documentBg.svg');

        h3, h6 {
            color: $TextPurple;
        }

        h3 {
            font-size: 2.56vw;
            line-height: 3.1vw;
            word-break: break-word;
        }

        
    }

    &__document-sign {
        position: absolute;
        bottom: 2vw;
        
        h6 {
            margin-bottom: 5%;
        }
    }

    &__document-sign-place {
        border: 1px solid $TextGray;
        border-radius: 5px;
        width: 9.1vw;
        height: 3.7vw;
    }
}


.about-sp2b {

    &__cover {
        display: flex;
        width: 100%;
        height: 100%;
        background-image: url('../backgrounds/sp2bBg.svg');
        background-position: center 75vh;
        background-repeat: no-repeat;
        justify-content: center;
        align-items: center;
    }
    
    &__inner-content {
        align-items: center;
        max-width: 810px;
        width: 100%;   
        text-align: center;
        padding: 3%;

        p {
            margin-top: 4vh;
            margin-bottom: 2vh;
        }

        h3 {
            font-size: 3.2rem;
            line-height: 48px;
        }
    }

    &__choices {
        margin-top: 8%;
    }

    &__pages {

        span {
            opacity: 0;
            top: 10%;
            position: absolute;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;

            &:nth-child(1) {
                left: 9%;
                background-image: url('../backgrounds/PaperBg1.svg');
                width: 6vw;
                height: 4vw;
            }

            &:nth-child(2) {
                left: 22%;
                background-image: url('../backgrounds/PaperBg3.svg');
                width: 3vw;
                height: 3.5vw;
            }

            &:nth-child(3) {
                left: 11%;
                background-image: url('../backgrounds/PaperBg2.svg');  
                width: 6vw;
                height: 4vw;
            }

            &:nth-child(4) {
                right: 12%;
                background-image: url('../backgrounds/PaperBg1.svg');
                width: 5vw;
                height: 4vw;
            }

            &:nth-child(5) {
                right: 11%;
                background-image: url('../backgrounds/PaperBg4.svg');
                width: 8vw;
                height: 4vw;
            }

            &:nth-child(6) {
                right: 20%;
                background-image: url('../backgrounds/PaperBg2.svg');
                width: 5vw;
                height: 4vw;
            }
        }
    }
}

.about-sp2a {
    justify-content: center;
    align-items: center;
    
    &__inner-content {
        align-items: center;
        max-width: 810px;
        width: 100%;   
        text-align: center;
        padding: 3%;
        position: relative;
        z-index: 1;

        p {
            margin-top: 2vh;
            margin-bottom: 4vh;
        }

        h5 {
            color: $TextWhite;
            font-family: 'StemRegular';
            line-height: 31px;
            margin: 3vh 0;
        }
    }

    &__tablet-bg {
        max-height: 900px;
        max-width: 97vw;
        height: 97vh;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -0.7%;
        right: 0;
        margin: auto;
        background-repeat: no-repeat;
        background-image: url('../backgrounds/ipadBg.svg');
        background-position: center;
        background-size: contain;
    }
}

.about-sp2a1 {
    justify-content: center;
    align-items: center;
    
    &__inner-content {
        align-items: center;
        max-width: 960px;
        width: 100%;   
        text-align: center;
        padding: 3%;

        & > p {
            margin-top: 2vh;
        }
    }

    &__advantages {
        width: 100%;
        margin-top: 8vh;
        margin-bottom: 10vh;
        display: flex;
        justify-content: space-between;
    }

    &__adv {

        i {
            color: $TextWhite;
            font-size: 14.3rem;
            line-height: 143px;
            margin-bottom: 30px;
        }

        p {
            font-family: 'Stem-Bold';
        }
    }
}

.about-sp2a2 {
    
    &__left {
        width: 48%;
        flex-direction: column;
        padding: 20px;
    }

    &__inner-content {
        width: 100%;
        max-width: 495px;
        
        p {
            padding-right: 10%;
            margin-top: 4%;
        }

        a {
            margin-top: 15%;
        }
    }

    &__right {
        width: 52%;
    }

    &__graph { //değişecek
        margin-right: 20%;

        svg {
            background-color: $TextWhite;

            g {
                font-size: 1.5rem;
                font-family: 'stem-regular';
            }
        }
    }

    &__graph-nav-dot {
        width: 34% !important;
        text-align: center;
    }
}

.about-sp2a3 {
    justify-content: center;
    align-items: center;

    &__inner-content {
        max-width: 760px;
        padding: 20px;
        text-align: center;

        p {
            margin: 3vh 0;
        }
    }

    &__signed { //değişecek
        width: 100%;
        height: 270px;
        display: flex;

        img {
            width: 100%;
        }
    }

    a {
        margin: auto;
        margin-top: 5vh;
        cursor: pointer;
    }
}

.about-sp2a4 {
    justify-content: center;
    align-items: center;

    &__inner-content {
        align-items: center;
        max-width: 760px;
        width: 100%;
        padding: 20px;
        text-align: center;

        h2 {
            margin-top: 5vh;
        }

        p {
            margin: 1.5vh 0;
            paddinG: 0 10%;
        }
    }

    &__choices {
        margin-top: 5vh;
    }

    &__graph-nav {
        display: flex;
        margin: 0;
    }

    &__graph-nav-dot {
        width: 25%;
        list-style: none;
        background-color: $TextWhite;
        padding: 10px;
        cursor: pointer;
        opacity: 0.4;

        &[aria-selected="true"] {
            opacity: 1;
        }

        span {
            font-size: 1.3rem;
            color: rgb(102, 102, 102);
            font-family: 'StemRegular';
        }
        
        &:focus {
            outline: none;
        }
    }

    &__graph-content {
        background-color: $TextWhite;

        & > div {
            width: 720px;
            height: 270px;
            position: relative;
        }
    }
}

.konvajs-content {
    background-color: $TextWhite;

    cursor: url('../backgrounds/cursor-pen.png') -5 120, auto;

    &:active {
        cursor: url('../backgrounds/cursor-pen.png') -5 120, auto;
    }
}

.properties {
    
    &__side {
        display: flex;
        width: 50%;
        height: 100%;
        align-items: center;
        padding: 20px;
    }

    &__left {
        flex-direction: column;
        justify-content: center; 
        padding-right: 10px;

        h3 {
            color: $TextPurple;
            letter-spacing: 0.2em;
            width: 670px;
        }
    }

    &__right {
        padding-left: 10px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        span {
            position: absolute;
            width: 2.6%;
            height: 36.5vw;
            transform: skewX(-15deg);
            background-color: $TextGreen;
            bottom: 0;
            right: 0;
            margin: auto;

            &:nth-of-type(1) {
                top: 7%;
                left: -40.5%;
            }

            &:nth-of-type(2) {
                top: -5%;
                left: -27%;
            }

            &:nth-of-type(3) {
                top: 4%;
                left: -20%;
            }

            &:nth-of-type(4) {
                top: -2%;
                left: -8.5%;
            }

            &:nth-of-type(5) {
                top: 0.5%;
                left: 0.5%;
            }

            &:nth-of-type(6) {
                top: 4%;
                left: 9%;
            }

            &:nth-of-type(7) {
                top: -2%;
                left: 20.5%;
            }

            &:nth-of-type(8) {
                top: 7%;
                left: 27.5%;
            }

            &:nth-of-type(9) {
                top: -5%;
                left: 41%;
            }
        }
    }

    &__content {
        display: flex;
        max-width: 670px;

        & > div {
            width: 47%;

            p {
                font-family: 'StemRegular';
                font-size: 1.8rem;
                color: $TextGray;
                margin: 1vw 0;
            }
        }
    }

    &__content-left {
        margin-right: auto; 
    }

    &__content-right {
        margin-left: auto; 
        position: relative;
    }

    &__image-blocks {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        height: 35vw;
        min-height: 35vw;
        margin-top: 10%;
    }

    &__image {
        width: 95%;
        height: 25vw;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        margin: auto;

        &.sp1 {
            z-index: 1;
            background-image: url('../backgrounds/lagina-s1.png');
            bottom: 0;
        }

        &.sp2 {
            z-index: 2;
            background-image: url('../backgrounds/lagina-s2.png');
            top: 0;
            bottom: 0;
        }

        &.sp3 {
            z-index: 3;
            background-image: url('../backgrounds/lagina-s3.png');
            top: 0;
        }
    }
}

.advantages {
    background-color: $BgAdvantages;
    background-image: url('../backgrounds/AdvantagesBg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__disk-content {
        width: 85%;
        height: 41.73vh;
        padding: 20px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20vh;
        margin-bottom: 10vh;
    }

    &__bottom-content {
        width: 85%;
        display: flex;
        position: relative;
        padding: 20px;
        padding-top: 10px;

        h3 {
            color: $TextWhite;
            letter-spacing: 0.2em;
        }

        p {
            font-size: 2.4rem;
            color: $TextWhite;
            margin-top: 5px;
        }
    }

    &__info {
        max-width: 670px;
        
        p {
            padding-right: 10%;
        }
    }

    &__navigation {
        width: 9vw;
        display: flex;
        justify-content: space-between;
        position: absolute;
        right: 0;
        top: 40px;

        & > div {
            width: 3.7vw;
            height: 3.7vw;
            background-color: $BgNav;
            border-radius: 50%;
            display: inline-flex;
            text-align: center;
            color: $TextGreen;
            justify-content: center;
            align-items: center;
            font-size: 2.2vw;
            cursor: pointer;
        }
    }

    &__disk-item {
        background-color: $BgDisk;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__disk-main {
        width: 16.36vw;
        height: 16.36vw;
        position: relative;
        color: $TextGreen;
        font-size: 9.7vw;

        &:after {
            content: "";
            top: 0;
            bottom: 0;
            left: -50%;
            right: -50%;
            margin: auto;
            width: 21.15vw;
            height: 21.15vw;
            border: 3px dotted $TextWhite;
            border-radius: 50%;
            position: absolute;
        }
    }

    &__disk-other {
        position: absolute;
        margin: auto;
        color: $TextWhite;

        &:nth-of-type(2) {
            width: 8.56vw;
            height: 8.56vw;
            left: 0;
            top: 25%;
            bottom: 0;
            font-size: 4.5vw;
        }

        &:nth-of-type(3) {
            width: 5.68vw;
            height: 5.68vw;
            top: 0;
            left: 12.5%;
            font-size: 2.3vw;
        }

        &:nth-of-type(4) {
            width: 9.77vw;
            height: 9.77vw;
            bottom: 0;
            left: 15%;
            font-size: 4.7vw;
        }

        &:nth-of-type(5) {
            width: 5.88vw;
            height: 5.88vw;
            top: 0;
            bottom: 25%;
            left: 27%;
            font-size: 3vw;
        }

        &:nth-of-type(6) {
            width: 7.68vw;
            height: 7.68vw;
            top: 55%;
            bottom: 0;
            right: 27%;
            font-size: 3vw;
        }

        &:nth-of-type(7) {
            width: 6.85vw;
            height: 6.85vw;
            top: 25%;
            right: 18%;
            font-size: 3.4vw;
        }

        &:nth-of-type(8) {
            width: 4.7vw;
            height: 4.7vw;
            bottom: -5%;
            right: 16%;
            font-size: 1.8vw;
        }

        &:nth-of-type(9) {
            width: 10.65vw;
            height: 10.65vw;
            top: 28%;
            bottom: 0;
            right: 0;
            font-size: 5.5vw;
        }
    }
}

.references {
    background: rgba(95,47,170,1);
    background: -moz-linear-gradient(45deg, rgba(95,47,170,1) 0%, rgba(34,188,152,1) 100%);
    background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(95,47,170,1)), color-stop(100%, rgba(34,188,152,1)));
    background: -webkit-linear-gradient(45deg, rgba(95,47,170,1) 0%, rgba(34,188,152,1) 100%);
    background: -o-linear-gradient(45deg, rgba(95,47,170,1) 0%, rgba(34,188,152,1) 100%);
    background: -ms-linear-gradient(45deg, rgba(95,47,170,1) 0%, rgba(34,188,152,1) 100%);
    background: linear-gradient(45deg, rgba(95,47,170,1) 0%, rgba(34,188,152,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5f2faa', endColorstr='#22bc98', GradientType=1 );
    padding: 20px;

    .react-tabs { //react-tab app fix
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        max-width: 680px;
    }

    &__info {
        margin: 25px 0;
        min-height: 34vmin;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
            font-size: 2.1rem;
            color: $TextWhite;

            &:last-child {
                margin-top: 15px;
            }
        }
    }

    &__image {
        width: 223px;
        height: 170px;
        display: flex;
        align-items: flex-end;
    }

    &__tab {
        text-align: center;
    }

    &__tab-dot {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: $TextWhite;
        margin: 0 7px;
        display: inline-block;
        cursor: pointer;
        opacity: 0.3;
        

        &[aria-selected="true"] {
            opacity: 1;
        }
    }
}

.about-us {
    background-image: url('../backgrounds/AboutUsBg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &__side {
        display: flex;
        width: 50%;
        height: 100%;
        align-items: center;
        padding: 20px;
    }

    &__left {
        align-items: center;
        justify-content: center;
        padding-right: 10px;
    }

    &__icon {
        display: flex;
        align-items: center;
        height: 195px;
        color: $TextWhite;
        background-repeat: no-repeat;
        background-position: left;
        background-image: url('../backgrounds/LaginaIcon.svg');
        background-size: contain;
        font-size: 5.4rem;
        font-family: StemBold;
        padding-left: 230px;
        margin-left: 12%;
    }

    &__content {
        max-width: 545px;
        margin-right: 28%;
    }

    &__right {
        flex-direction: column;
        justify-content: center;
        padding-left: 10px;
        

        h3 {
            color: $TextWhite;
            font-size: 3.2rem;
        }

        p {
            color: $TextWhite;
            font-size: 2.1rem;
            margin-top: 15px;
        }
    }
}

.contact {
    
    &__side {
        width: 50%;
        display: flex;
    }

    &__left {
        flex-direction: column;
    }

    &__form {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        max-width: 650px;
        margin: auto;
        height: 77.4%;
        min-height: 620px;
        padding: 20px;

        form {
            width: 100%;
            margin-top: 4vh;

            i {
                display: none;
            }
        }

        h3 {
            color: $TextGreen;
        }

        button {
            display: block;
            width: 100%;
            height: 70px;
            background-color: $TextGreen;
            text-align: center;
            line-height: 70px;
            border-radius: 50px;
            color: $TextWhite;
            font-size: 2.4rem;
            border: none;
            margin-top: 5%;
            cursor: pointer;

            &:hover, &:focus {
                outline: none;
            }

            &.disabled {
                background: rgba($TextGreen, .4);
                cursor: not-allowed;
            }
        }
    }

    &__form-input {
        margin: 4% 0;

        label {
            color: $TextGray;
            font-size: 1.4rem;
        }

        input, textarea {
            width: 100%;
            border: none;
            border-bottom: 1px solid $BorderInput;
            resize: none;
            font-size: 2.4rem;
            color: $TextGray;

            &:hover, &:focus {
                outline: none;
            }
        }

        textarea {
            height: 110px;
        }
    }

    &__address {
        width: 100%;
        height: 22.6%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px;
        background-color: $TextGray;
    }

    &__info {
        text-align: center;

        h4 {
            color: $TextWhite;
            font-size: 2.4rem;

        }
    }

    &__info-text {
        margin: 2vh 0;

        p {
            display: inline-block;
            color: $TextWhite;
            font-size: 1.8rem;
            
            &:last-child {
                display: block;
            }
        }
    }

    &__show-form {
        display: none;
        position: absolute;
        z-index: 5;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 66px;
        font-size: 1.8rem;
        color: $TextWhite;
        font-family: 'StemBold';
        background-color: $TextGreen;
        padding-left: 80px;
        background-image: url('../backgrounds/show-form.svg');
        background-repeat: no-repeat;
        background-position: 30px center;
        line-height: 66px;
        cursor: pointer;
    }

    &__tab-dot {
        width: 20px;
        height: 20px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: $TextGreen;
        display: inline-block;
        cursor: pointer;
        opacity: 0.4;

        &[aria-selected="true"] {
            opacity: 1;
        }
    }

    &__map {
        width: 50%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
    }
}


@media screen and (max-width: 1440px) {

    .section__page {

        h2 {
            font-size: 4vw;
            line-height: 5.3vw;
        }

        h3 {
            font-size: 3.2vw;
        }
    }

    .about-sign {

        h2 {
            font-size: 4vw;
            line-height: 5.3vw;
        }

    }

    .about-sp2a3 {

        &__signed {
            height: 220px;
        }
    }

    .properties {

        &__content > div {
            
            p {
                font-size: 1.6rem;
            }
        }

        &__left {

            h3 {
                width: 100%;
            }
        }
    }

    .references {

        &__info {
            min-height: 26vmin;

            p {
                font-size: 1.8rem;
            }
        }
    }

    .about-us {

        &__icon {
            font-size: 3.6vw;
            height: 12.5vw;
            padding-left: 35%;
            margin: 0;
        }

        &__content {
            margin: 0;
        }

        &__left {
            width: 45%;
        }

        &__right {
            width: 55%;

            h3 {
                font-size: 2.2vw;
            }

            p {
                font-size: 1.8rem;
            }
        }
    }
    
    .contact {

        &__form {
            min-height: 550px;
            
            h1 {
                font-size: 3vw;
            }

            button {
                height: 5vw;
                line-height: 5vw;
            }
        }

        &__form-input {
            margin: 2% 0;

            textarea {
                height: 7.5vw;
            }
        }

        &__address {
            min-height: 180px;
        }
    }

    .about-sp2a2 {

        &__graph {
            width: 100%;
        }
    }

    .about-sp2a4 {

        &__graph-content {
    
            & > div {
                height: 220px;
            }
        }
    }
}

@media screen and (max-width: 1366px) {

    .about-sp2a {

        h2 {
            font-size: 3vw;
            line-height: 3.3vw;
        }

        &__inner-content {
            max-width: 735px;
        }

        &__choices {
            max-width: 590px;

            a {
                width: 280px;
                height: 60px;
                line-height: 60px;
            }
        }
    }

    .about-sp2a3 {

        &__inner-content {
            max-width: 700px;
        }

        &__signed {
            height: 180px;
        }
    }

    .about-sp2a4 {

        &__graph-content {
    
            & > div {
                width: 660px;
                height: 180px;
            }
        }
    }

    .references {

        &__image {
            width: 175px;
        }
    }

    .contact {

        &__form {
            min-height: 460px;
            padding: 10px;

            form {
                margin-top: 0;
            }

            h3 {
                font-size: 2.6vw;
            }

            button {
                height: 4vw;
                line-height: 4vw;
                margin-top: 2vh;
            }

            textarea {
                height: 5vw;
            }
        }

        &__address {
            min-height: 130px;
            padding: 10px;
        }

        &__tab {
            margin: 0;
        }
    }
}

@media screen and (max-width: 1024px) {

    .about-sp2a__tablet-bg { display: none; }
}

@media screen and (max-width: 991px) {

    .references {

        &__image {
            height: 90px;
        }
    }
    
    .contact {
        
        &__left {
            width: 100%;
        }

        &__form {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 10vmax;
            order: 2;
            max-width: unset;
            min-height: unset;
            align-items: flex-start;
            margin-top: 0;
            padding: 20px 9%;
            background-color: $TextWhite;

            form {
                margin-top: 0;
                display: none;
                width: 100%;
                height: 100vh;
                position: absolute;
                top: 0;
                left: 0;
                padding: 9%;
                background-color: $TextWhite;
                z-index: 9999;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                overflow-y: scroll;

                i {
                    display: block;
                    color: $TextGray;
                    font-size: 3rem;
                }

                input {
                    height: 50px;
                }

                textarea {
                    height: 120px;
                }

                &.active {
                    display: flex;
                }

                button {
                    width: 300px;
                    height: 70px;
                    line-height: 70px;
                    font-size: 2.4rem;
                }
            }
        }

        &__form-input {
            width: 100%;
        }

        &__address {
            height: 45vmax;
            order: 1;
            margin-top: 10vmax;
            paddinG: 9%;
            align-items: flex-start;
        }

        &__info {
            text-align: start;

        }

        &__info-text {
            margin: 5% 0;

            p {
                display: block;
            }
        }

        &__map {
            width: 100%;
            height: calc(45vmax - 66px);
            top: 55vmax;
        }

        &__show-form {
            display: block;
        }
    }

    .about-sp2b__pages { display: none; }
}

@media screen and (max-width: 768px) {

    .section__page {

        h3 {
            font-size: 4.4vw;
        }
    }

    .about-sign__nav, .about-sign__nav--other {
        width: 39vw;
        height: 9vw;
        line-height: 9vw;
        transition: none;
        z-index: 5;
        position: relative;
        font-size: 3.5vw;
    }
    
    .home {
        padding: 8% 0 0 8%;
        flex-direction: column;

        &__side {
            width: 100%;
            height: 50%;
        }

        &__left {
            justify-content: flex-start;
            align-items: flex-start;
        }

        &__content {
            margin-top: 0;
            margin-left: 0;

            h1 {
                margin-top: 10%;
                font-size: 8vw;
                line-height: 9.2vw;
            }
            button{
                font-size: 2.8vw;
                padding: 10px 30px;
            }
        }

        &__icon {
            height: 19vw;
            font-size: 3.8vw;
            padding-left: 48%;
        }

        &__right {
            position: relative;
            align-items: flex-end;
            pointer-events: none;
        }

        &__image {
            width: 85vw;
            height: 85vw;
            max-width: 400px;
            max-height: 400px;
            left: unset;
            right: -20%;
            bottom: -20%;
            position: absolute;
        }
    }

    .about-home {
        flex-direction: column;

        &__nav {
            margin-top: 8%;
        }

        &__side {
            width: 100%;
            height: 50%;
        }

        &__left {
            
            h2 {
                font-size: 5.5vw !important;
                line-height: 7vw !important;
                top: 7%;
                left: 11%;
                max-width: 50vw;
            }
        }

        &__big-rectangle {
            top: 10%;
            left: 0;
            right: 0;
            bottom: unset;
            width: 70vw;
            height: 70vw;
        }

        &__rectangle-icon {
            width: 9vw;
            height: 9vw;
            top: 5%;
            right: 22%;
        }

        &__triangle-icon {
            width: 13vw;
            height: 13vw;
            right: unset;
            left: 28%;
        }

        &__burger-icon {
            left: unset;
            width: 14vw;
            height: 14vw;
            top: 36%;
            right: 8%;
            z-index: 5;
        }

        &__circle-icon {
            width: 12vw;
            height: 12vw;
            top: 23%;
        }

        &__disk {
            width: 20vw;
            height: 20vw;
            top: 4%;
            right: -7%;
        }

        &__shadow-rectangle {
            width: 25vw;
            height: 25vw;
            left: unset;
            bottom: unset;
            top: 22%;
            right: 10%;
        }

        &__sign {
            width: 33vw;
            height: 20vw;
            top: 0;
            bottom: 25%;
            left: 6%;
            right: unset;
        }

        &__right-content {
            top: unset;
            bottom: 12%;
        }

        &__right {

            h1 {
                font-size: 13vmin;
                line-height: 16vmin;
                max-width: 66vmin;
            }
        }
    }

    .properties {
        flex-direction: column;
        padding: 9%;
        
        &__side {
            width: 100%;
            height: 50%;
            padding: 0;
        }

        &__left {
            order: 2;
            align-items: flex-start;
        }

        &__right {
            
            span {
                width: 1.8%;
                height: 80%;

                &:nth-of-type(1) {
                    left: -28.5%;
                }

                &:nth-of-type(2) {
                    left: -19%;
                }

                &:nth-of-type(3) {
                    left: -14%;
                }

                &:nth-of-type(4) {
                    left: -7%
                }

                &:nth-of-type(5) {
                    left: -1%;
                }

                &:nth-of-type(6) {
                    left: 5%;
                }

                &:nth-of-type(7) {
                    left: 12%;
                }

                &:nth-of-type(8) {
                    left: 17%;
                }

                &:nth-of-type(9) {
                    left: 26%;
                }
            }
        }

        &__content {
            overflow-y: scroll;
            flex-direction: column;
            max-width: unset;
            margin-top: 2vh;

            & > div {
                width: 100%;
                margin: 0;
            }
        }

        &__image-blocks {
            width: 70%;
            height: 45vw;
            min-height: 45vw;
            margin-top: 5%;
        }

        &__image {
            background-position: center;
            width: 100%;
            height: 33vw;
        }
    }

    .advantages {

        &__disk-content {
            width: 100%;
            height: 50%;
            padding: 0;
            margin: 4vh 0;
        }

        &__disk-main {
            width: 41vw;
            height: 41vw;
            font-size: 25vw;

            &:after {
                width: 53vw;
                height: 53vw;
            }
        }

        &__disk-other {
            display: none;

            &:nth-of-type(5) {
                display: flex;
                left: 0.7%;
                width: 14.6vw;
                height: 14.6vw;
                font-size: 8vw;
            }

            &:nth-of-type(6) {
                display: flex;
                right: 0;
                width: 19.2vw;
                height: 19.2vw;
                font-size: 8vw;
            }
        }

        &__bottom-content {
            width: 100%;
            height: calc(50% - 8vh);
            padding: 9%;
            position: relative;
        }

        &__info {
            max-width: unset;
            
            p {
                padding-right: 0;
            }
        }

        &__navigation {
            width: 23vw;
            top: unset;
            bottom: 20%;
            left: 0;
            margin: auto;

            & > div {
                width: 9.6vw;
                height: 9.6vw;
                font-size: 5.5vw;
            }
        }
    }

    .references {

        &__image {
            width: 29vmin;
        }

        &__info {
            margin: 7vh 0;
        }
    }

    .about-us {
        flex-direction: column;
        padding: 14%;

        &__side {
            width: 100%;
            padding: 0;
        }

        &__right {
            order: 1;
            height: 77%;

            h3 {
                font-size: 3.8vw;
            }
        }

        &__left {
            order: 2;
            height: 23%;
        }

        &__content {
            max-width: unset;
            height: 100%;

            p {
                max-height: 60%;
                overflow-y: scroll;
            }
        }

        &__icon {
            text-align: center;
            padding-left: 0;
            padding-top: 33%;
            background-position: top;
            background-size: 75%;
        }
    }

    // Contact begins at 991px

    .about-sign {
        overflow-y: hidden; 

        &__inner-content {
            max-width: unset;
            padding: 0;

            p {
                padding: 0;
            }
        }

        h2 {
            font-size: 5vw;
            line-height: 7vw;
        }

        &__turn-back {
            top: 1.2%;
        }

        &__sign-page {
            flex-direction: column;
            padding: 9%;
        }

        &__side {
            width: 100%;
            padding: 0;
            align-items: unset;
        }

        .about-sp1 {

            &__document {
                width: 24.42vw;
                
                &:nth-child(1) {
                    top: 7vh;

                    &:after {
                        width: 34.4vw;
                        height: 34.4vw;
                    }
                }

                &:nth-child(2) {
                    top: 18vh;

                    &:after {
                        left: 12%;
                        width: 22.5vw;
                        height: 22.5vw;
                    }
                }

                &:nth-child(3) {
                    top: 7vh;

                    &:after {
                        left: 40%;
                        width: 18.4vw;
                        height: 18.4vw;
                    }
                }

                &:nth-child(4) {
                    left: -12%;
                    bottom: -11vh;

                    &:after {
                        top: 25%;
                        width: 24.7vw;
                        height: 24.7vw;
                    }
                }

                &:nth-child(5) {
                    bottom: -15vw;
                    right: -13vw;

                    &:after {
                        top: 5%;
                        width: 18.4vw;
                        height: 18.4vw;
                    }
                }
            }
        }

        .about-sp2 {

            &__left {
                height: 50%;
                order: 2;
            }

            &__right {
                height: 50%;
                order: 1;
                align-items: center;

                span {
                    width: 1.8%;
                    height: 80%;
    
                    &:nth-of-type(1) {
                        left: -28.5%;
                    }
    
                    &:nth-of-type(2) {
                        left: -19%;
                    }
    
                    &:nth-of-type(3) {
                        left: -14%;
                    }
    
                    &:nth-of-type(4) {
                        left: -7%
                    }
    
                    &:nth-of-type(5) {
                        left: -1%;
                    }
    
                    &:nth-of-type(6) {
                        left: 5%;
                    }
    
                    &:nth-of-type(7) {
                        left: 12%;
                    }
    
                    &:nth-of-type(8) {
                        left: 17%;
                    }
    
                    &:nth-of-type(9) {
                        left: 26%;
                    }
                }
            }

            &__document {
                width: 35%;
                height: 40vw;
                margin-right: 0;
                padding: 3vw;
            }

            &__document-sign {
                bottom: 5vw;
            }

            &__inner-content {
                margin: 0;
            }

            &__choices {
                margin-top: 4vh;
            }
        }

        .about-sp2b {
            padding: 0;

            &__cover {
                padding: 9%;
            }

            h3 {
                font-size: 4.4vw;
                line-height: 7vw;
            }
        }

        .about-sp2a1 {

            &__inner-content > p {
                margin-top: 5%;
            }

            &__advantages {
                margin: 15% 0;
            }

            &__adv {

                i {
                    font-size: 16vw;
                    line-height: 16.4vw;
                    margin-bottom: 3vw;
                }

                p {
                    font-size: 3vw;
                }
            }
        }

        .about-sp2a2 {

            &__left {
                order: 2;
                height: 50%;
            }

            &__right {
                order: 1;
                height: 50%;
                align-items: center;
            }

            &__inner-content {

                a {
                    margin-top: 8%;
                }
            }

            &__graph {
                margin-right: 0;
            }
        }

        .about-sp2a4 {

            &__graph-content {
        
                & > div {
                    width: 85vw;
                    height: 180px;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {

    .section__page {
        min-height: 420px;

        h3 {
            font-size: 5.6vw;
            letter-spacing: 0;
        }

        p {
            font-size: 1.3rem !important;
        }
    }

    .about-sign__nav, .about-sign__nav--other {
        width: 50vw;
        height: 12vw;
        line-height: 12vw;
        font-size: 4.2vw;
    }

    .about-sign__nav--other, .about-sign__nav--other--other {
        margin-right: 2%;
    }

    .home {
        padding: 13% 0 0 13%;

        &__content {

            h1 {
                font-size: 11vw;
                line-height: 14vw;
                margin-top: 7%;
            }
        }

        &__icon {
            height: 24vw;
            font-size: 4.8vw;
            padding-left: 40%;
        }
    }

    .about-home {

        &__left {
            height: 47%;

            h2 {
                font-size: 6vmin !important;
                line-height: 8vmin !important;
                top: 10%;
                left: 13%;
                max-width: 55vmin;
            }
        }

        &__right {
            height: 53%;

            h1 {
                font-size: 14.5vw;
                min-width: 75vw;
            }
        }

        &__right-content {
            left: unset;
        }

        &__big-rectangle {
            top: 12%;
        }

        &__triangle-icon {
            display: none;
        }
    }

    .properties {
        padding: 13%;
        padding-top: 7%;

        &__left {
            height: 60%;
        }

        &__right {
            height: 40%;
        }

        &__image-blocks {
            width: 80%;
        }
    }

    .advantages {
        padding: 13%;

        &__disk-content {
            height: 40%;
        }

        &__disk-other {
            display: none !important;
        }

        &__bottom-content {
            padding: 0;
            height: calc(55% - 8vh);
        }

        &__info {
            margin-top: 8%;
        }

        &__navigation {
            width: unset;
            justify-content: center;
            bottom: 0;

            & > div {
                width: 73px;
                height: 73px;
                margin: 0 15px;
                font-size: 13vw;
            }
        }
    }

    .references {

        &__info {
            margin: 3vh 0;

            p:nth-child(1) {
                overflow-y: scroll;
                height: 200px;
            }
        }
    }

    .about-us {

        p {
            max-height: 55%;
        }
    }

    .about-sign {
        min-height: unset;

        &__turn-back {

            i {
                left: -10px;
                top: 0px;
                font-size: 2rem;
            }
        }

        h2 {
            font-size: 8vw;
            line-height: 9vw;
        }

        h3 {
            font-size: 5vw;
            line-height: 6vw;
        }

        .about-sp1 {

            &__left {
                height: 40%;
            }

            &__right {
                height: 60%;
            }
            
            &__document {

                &:nth-child(1) {
                    top: 0;
                }

                &:nth-child(2) {
                    top: 13vh;
                }

                &:nth-child(3) {
                    top: 0;

                    &:after {
                        top: 40%;
                    }
                }

                &:nth-child(4) {
                    bottom: 0;
                }

                &:nth-child(5) {
                    bottom: 0;
                }
            }
        }

        .about-sp2 {
            
            &__document {
                width: 49.5%;
                height: 50vw;
            }
        }

        .about-sp2a2 {

            &__graph svg g {
                font-size: 1.2rem;
            }
        }

        .about-sp2a3 {

            &__inner-content {
                align-items: center;
            }

            &__signed {
                width: 85vw;
                height: 150px;
            }
        }
    }
}

@media (min-width:767px) and (max-width:1199px) {
    .home__content{
        button{
            font-size:1.8rem;
            padding: 10px 34px;
        }
    }
}​

// Alert
.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    font-size: 16px;

    &.alert-success {
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
        display: block !important;
    }
    &.alert-danger {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
        display: block !important;
    }
}

.grecaptcha-badge {
    display: none !important;
}