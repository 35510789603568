//General Animation

@keyframes slideFromTop {
  0% { 
    opacity: 0;
    transform: translateY(-30%) 
  }

  100% { 
    opacity: 1;
    transform: translateY(0%) 
  }
}

@keyframes slideFromRight {
  0% { 
    opacity: 0;
    transform: translateX(20%) 
  }

  100% { 
    opacity: 1;
    transform: translateX(0%) 
  }
}

@keyframes slideFromLeft {
  0% { 
    opacity: 0;
    transform: translateX(-20%) 
  }

  100% { 
    opacity: 1;
    transform: translateX(0%) 
  }
}

@keyframes slideFromLeftButton {
  0% { 
    opacity: 0;
    transform: translateX(-50%) 
  }

  100% { 
    opacity: 1;
    transform: translateX(0%) 
  }
}

@keyframes downFromTop {
  0% { 
    opacity: 0;
    transform: translateY(-100vh) 
  }

  10% {
    opacity: 1;
  }

  100% { 
    opacity: 1;
    transform: translateX(0vh) 
  }
}

// .fp-section.fp-table.active, .fp-section.fp-table.active {
//   z-index: 999 !important;
//   animation: downFromTop 0.7s ease-out 1 both;
// }

// .fp-section.fp-table.fp-completely, .fp-section.fp-table.fp-completely {
//   z-index: 99 !important;
// }

.about-sp2, .active .properties {

  &__blocks {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: slideFromRight 1s ease-out 1 both;
  }
}

.active .properties__image {
  animation: slideFromTop 0.3s ease-out 1 both;

  &.sp1 {
    animation-delay: 0.4s;
  }

  &.sp2 {
    animation-delay: 0.6s;
  }

  &.sp3 {
    animation-delay: 0.8s;
  }
}

.active, .fp-completely {

  .home {

    &__icon {
      animation: slideFromLeft 1s ease-out 1 both;
    }

    h1 {
      animation: slideFromLeft 1s ease-out 1 both;
      animation-delay: 0.2s;
    }

    button {
      animation: slideFromLeftButton 1s ease-out 1 both;
      animation-delay: 0.2s;
    }

    &__image {
      animation: slideFromRight 1s ease-out 1 both;
      animation-delay: 0.2s;
    }
  }

  .about-home {

    h2 {
      animation: slideFromLeft 1s ease-out 1 both;
    }

    h1 {
      animation: slideFromRight 1s ease-out 1 both;
    }
  }

  .properties {

    &__content-left {
      animation: slideFromLeft 1s ease-out 1 both;
    }

    &__content-right {
      animation: slideFromLeft 1s ease-out 1 both;
      animation-delay: 0.2s;
    }
  }

  .about-us {

    &__icon {
      animation: slideFromLeft 1s ease-out 1 both;
    }

    &__content {
      animation: slideFromRight 1s ease-out 1 both;
    }
  }
}

.about-sign {

  .about-sp1, .about-sp2, .about-sp2a2 {

    &__inner-content {
      animation: slideFromLeft 1s ease-out 1 both;
    }

    &__document, &__graph {
      animation: slideFromRight 1s ease-out 1 both;
    }
  }
}



//Advantages Animation

@keyframes intoTheVoid1 {
  0% { 
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% { 
    opacity: 1;
  }
}

@keyframes intoTheVoid2 {
  0% { 
    opacity: 1;
    transform: translate3d(0,0,0);
  }

  50% {
    opacity: 0;
    transform: translate3d(38vw, -3.5vw, 0);
  }

  100% { 
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

@keyframes intoTheVoid3 {
  0% { 
    opacity: 1;
    transform: translate3d(0,0,0);
  }

  50% {
    opacity: 0;
    transform: translate3d(29vw, 10vw, 0);
  }

  100% { 
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

@keyframes intoTheVoid4 {
  0% { 
    opacity: 1;
    transform: translate3d(0,0,0);
  }

  50% {
    opacity: 0;
    transform: translate3d(25vw, -7.5vw, 0);
  }

  100% { 
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

@keyframes intoTheVoid5 {
  0% { 
    opacity: 1;
    transform: translate3d(0,0,0);
  }

  50% {
    opacity: 0;
    transform: translate3d(16.5vw, 3vw, 0);
  }

  100% { 
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

@keyframes intoTheVoid6 {
  0% { 
    opacity: 1;
    transform: translate3d(0,0,0);
  }

  50% {
    opacity: 0;
    transform: translate3d(-16vw, -7vw, 0);
  }

  100% { 
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

@keyframes intoTheVoid7 {
  0% { 
    opacity: 1;
    transform: translate3d(0,0,0);
  }

  50% {
    opacity: 0;
    transform: translate3d(-23.5vw, 3vw, 0);
  }

  100% { 
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

@keyframes intoTheVoid8 {
  0% { 
    opacity: 1;
    transform: translate3d(0,0,0);
  }

  50% {
    opacity: 0;
    transform: translate3d(-26.5vw, -11.5vw, 0);
  }

  100% { 
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

@keyframes intoTheVoid9 {
  0% { 
    opacity: 1;
    transform: translate3d(0,0,0);
  }

  50% {
    opacity: 0;
    transform: translate3d(-37vw, -3vw, 0);
  }

  100% { 
    opacity: 1;
    transform: translate3d(0,0,0);
  }
}

.advantages__disk-item.active {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;

  &:nth-of-type(1) {
    animation-name: intoTheVoid1;
  }

  &:nth-of-type(2) {
    animation-name: intoTheVoid2;
  }

  &:nth-child(3) {
    animation-name: intoTheVoid3;
  }

  &:nth-child(4) {
    animation-name: intoTheVoid4;
  }

  &:nth-child(5) {
    animation-name: intoTheVoid5;
  }

  &:nth-child(6) {
    animation-name: intoTheVoid6;
  }

  &:nth-child(7) {
    animation-name: intoTheVoid7;
  }

  &:nth-child(8) {
    animation-name: intoTheVoid8;
  }

  &:nth-child(9) {
    animation-name: intoTheVoid9;
  }
}



//Falling Pages Animation

@keyframes fallingPages {
  0% { 
    opacity: 0;
    transform: translateY(0);
  }

  5% { 
    opacity: 1;
    transform: translateY(5vh);
  }

  95% {
    opacity: 1;
  }

  100% { 
    opacity: 0;
    transform: translateY(80vh);
  }
}

.about-sp2b {
  overflow-y: hidden;

  &__cover {
    overflow-y: hidden;
  }

  &__pages {

    span {
      animation-name: fallingPages;
      animation-duration: 4s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;

      &:nth-child(1) {
        animation-delay: 0.2s;
      }

      &:nth-child(2) {
        animation-delay: 1.5s;
      }

      &:nth-child(3) {
        animation-delay: 2.7s;
      }

      &:nth-child(4) {
        animation-delay: 1s;
      }

      &:nth-child(5) {
        animation-delay: 2.3s;
      }

      &:nth-child(6) {
        animation-delay: 3s;
      }
    }
  }
}


@media screen and (max-width: 768px) {

  .advantages__disk-item.active {
    animation: none;
  }
}




